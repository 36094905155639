export const environment = {
  production: false,
  name: 'demo',
  CMSDomain: 'https://cmsdemo.extemporeapp.com',
  StudentDomain: 'https://studentdemo.extemporeapp.com',
  NapiStudentDomain: 'https://napidemo.extemporeapp.com',
  ServerDomain: 'https://napidemo.extemporeapp.com',
  NApiDomain: 'https://napidemo.extemporeapp.com/api',
  StoreDomain: 'https://storedemo.extemporeapp.com',
  CloudFrontUrl: 'https://d2vd52s4ybmc4a.cloudfront.net',
  EdlinkClientId: '89894ff6-5443-4f24-b7a6-a03edcf80e46',
  EdlinkState: '3xt3mp0r3d3m0',
  CleverDomain: 'https://api.clever.com/v2.0',
  CleverId: '4cbf09b0f5a7b5a6067f',
  CleverDistrict: '5e73d2cce69cd40001074ea6',
  ClassLinkId: 'c16502948067018fd4887a7ad2c3d6193733ec1ccb4a',
  ClassLinkState: '3xt3mp0r3d3m0',
  FirebaseConfig: {
    apiKey: "AIzaSyApMSeHVGDYiPNx-WOlqsMY-fJuRoWVvtA",
    authDomain: "extempore-cc762.firebaseapp.com",
    databaseURL: "https://extempore-cc762.firebaseio.com",
    storageBucket: "extempore-cc762.appspot.com",
  },
  FirebaseUserRoot: 'usersdemo',
  FirebaseAdminUsers: 'admindemousers',
  Buckets: {
    class: 'extempore-development',
    attempt: 'extempore-development',
    questionAttachment: 'extempore-development',
    profileImage: 'extempore-development',
    logo: 'extempore-development',
    assets: 'extempore-assets-dev',
  },
  awsLambdaUrls: {
    mediaUploadPresignedUrl: 'https://chb6jlq3dkf4igxqtjqcneymcu0prhwh.lambda-url.us-east-1.on.aws/'
  },
  stripeKey: 'pk_test_vf5NHrfblJTqgFkfIpvBYrQJ',
  analyticsProperty: 'UA-66422851-4',
  captchaSiteKey: '6LcB-bIlAAAAALiDESxRncTLsH1a39s0C8unfOZx',
  intercomAppId: 'epbgjnks',
  intercomSecretKey: '4P0ssYNnJ7HQwV76Fkq28pFkzLMoIqaRk92iJKzl',
  intercomBearerToken: 'Bearer dG9rOmQzZTU3ZDE2XzEyYThfNDJiMl84ZDU0XzJmMDJmZGU4MzY5MToxOjA=',
  copyrightYear: new Date().getFullYear()
};
