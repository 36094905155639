export class Timer {
    timerStart: number;
    timeLeft: number;
    secondsAllowed: number;
    interval;
    mode: 'down' | 'up' = 'down';

    success: () => void;

    /**
     * Create a timer that can count down or up.
     * -1 for a timer that will count up.
     */
    constructor(seconds: number) {
        this.secondsAllowed = seconds;
        this.timerStart = Date.now();
        this.timeLeft = Math.round(this.secondsAllowed - (Date.now() - this.timerStart) / 1000);
        if (seconds === -1) {
            this.mode = 'up';
        }
    }

    /**
     * Start counting down
     */
    countDown() {
        if (!this.interval) {
            this.timerStart = Date.now();
            this.interval = setInterval(() => {
                this.timeLeft = Math.round(this.secondsAllowed - (Date.now() - this.timerStart) / 1000);

                if (this.timeLeft < 0) {
                    if (this.interval) {
                        clearInterval(this.interval);
                        this.interval = null;
                    }
                    if (this.success) {
                        this.success();
                    }
                }
            }, 1000);
        }
    }

    /**
     * This will never "finish"
     */
    countUp() {
        this.mode = 'up';
        if (!this.interval) {
            this.timerStart = Date.now();
            this.interval = setInterval(() => {
                this.timeLeft = Math.round((Date.now() - this.timerStart) / 1000);
            }, 1000);
        }
    }

    stopTimer() {
        clearInterval(this.interval);
        this.interval = null;
    }

    setNoTimeLeft() {
        this.clear();
        this.timeLeft = 0;
    }

    clear() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    then(success: () => void) {
        this.success = success;
    }
}
